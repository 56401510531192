input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
    /* -webkit-box-shadow: 0 0 0 30px #1f1f1f inset !important; */
    /* border: 1px solid green; */
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #1c1c1c inset;
  transition: background-color 5000s ease-in-out 0s;
}